import {
	MetaSiteModel,
	SiteAssetsClient,
	siteAssetsClientBuilder,
	SiteAssetsClientConfig,
	SiteAssetsCollaborators,
	SiteAssetsRequest,
	SiteAssetsSiteModels,
	SitePagesModel,
} from '@wix/site-assets-client'
import type {
	ProcessLevelSACFactoryParams,
	RequestLevelSACFactoryParams,
	SiteAssetsClientAdapter,
	TBSiteAssetsRequest,
} from './types'
import { getCommonParams, getUniqueParamsPerModule, toSiteAssetsRequest } from './toSiteAssetsRequest'
import { toMetaSiteModel, toSitePagesModel } from './toSiteAssetsModel'
import { nopSiteAssetsMetricsReporter } from './nopSiteAssetsMetricsReporter'
import { toSiteAssetsHttpClient } from './toSiteAssetsHttpClient'
import { Experiments } from '@wix/thunderbolt-symbols'
import { toFallbackStrategy } from './adapters/fallbackStrategy'

export function updateConfig(experiments: Experiments, config: SiteAssetsClientConfig): SiteAssetsClientConfig {
	const { mediaRootUrl, staticMediaUrl } = config.moduleTopology.publicEnvironment

	const relativeMediaRoot = experiments['specs.thunderbolt.relativeMediaRoot'] === true
	const mediaRootUrlToUse = relativeMediaRoot ? '/_media' : mediaRootUrl
	const staticMediaUrlToUse = relativeMediaRoot ? '/_media/media' : staticMediaUrl

	return {
		...config,
		moduleTopology: {
			...config.moduleTopology,
			publicEnvironment: {
				...config.moduleTopology.publicEnvironment,
				mediaRootUrl: mediaRootUrlToUse,
				staticMediaUrl: staticMediaUrlToUse,
			},
		},
	}
}

export const createSiteAssetsClientAdapter = ({
	fetchFn,
	config,
	siteAssetsMetricsReporter = nopSiteAssetsMetricsReporter(),
	manifests,
	moduleFetcher,
	onFailureDump = () => {},
	timeout,
}: ProcessLevelSACFactoryParams) => ({
	dataFixersParams,
	requestUrl,
	siteScopeParams,
	beckyExperiments,
	fallbackStrategyOverride,
	staticHTMLComponentUrl,
	remoteWidgetStructureBuilderVersion,
	deviceInfo,
	qaMode,
	experiments,
}: RequestLevelSACFactoryParams): SiteAssetsClientAdapter => {
	const collaborators: SiteAssetsCollaborators = {
		httpClient: toSiteAssetsHttpClient(requestUrl, fetchFn, config.moduleTopology.environment.siteAssetsServerUrl),
		moduleFetcher,
		metricsReporter: siteAssetsMetricsReporter,
	}

	const sitePagesModel: SitePagesModel = toSitePagesModel(dataFixersParams, siteScopeParams)
	const metaSiteModel: MetaSiteModel = toMetaSiteModel(dataFixersParams, siteScopeParams)

	const siteAssetsSiteModels: SiteAssetsSiteModels = {
		sitePagesModel,
		metaSiteModel,
	}

	const siteAssetsClient: SiteAssetsClient = siteAssetsClientBuilder(
		collaborators,
		updateConfig(experiments, config),
		siteAssetsSiteModels
	)

	return {
		executeTestModule(request: TBSiteAssetsRequest): void {
			const { moduleParams, pageCompId, pageJsonFileName } = request
			const { contentType } = moduleParams

			const siteAssetsRequest: SiteAssetsRequest = {
				disableSiteAssetsCache: true,
				endpoint: {
					controller: 'pages',
					methodName: 'thunderbolt',
				},
				module: {
					name: 'site-assets-test-module',
					version: '1.231.0',
					fetchType: 'module',
					params: {
						...getCommonParams(
							siteScopeParams,
							request,
							beckyExperiments,
							remoteWidgetStructureBuilderVersion
						),
						...getUniqueParamsPerModule({
							deviceInfo,
							staticHTMLComponentUrl,
							qaMode,
						})(moduleParams),
					},
				},
				contentType,
				fallbackStrategy: 'disable',
				pageJsonFileName: pageJsonFileName || sitePagesModel.pageJsonFileNames[pageCompId],
				timeout,
				customRequestSource: siteScopeParams.isInSeo ? 'seo' : undefined,
			}
			siteAssetsClient.execute(siteAssetsRequest).catch(() => {})
		},
		// result() returns a (Promise of) string or json depending on the content-type of the module output
		execute(request: TBSiteAssetsRequest, fallbackStrategy: string): Promise<string | any> {
			return siteAssetsClient
				.execute(
					toSiteAssetsRequest(
						request,
						manifests.node.modulesToHashes,
						sitePagesModel.pageJsonFileNames,
						siteScopeParams,
						beckyExperiments,
						staticHTMLComponentUrl,
						remoteWidgetStructureBuilderVersion,
						deviceInfo,
						qaMode,
						timeout,
						fallbackStrategyOverride
							? fallbackStrategyOverride
							: toFallbackStrategy(request.moduleParams.resourceType, fallbackStrategy)
					)
				)
				.catch((e) => {
					const moduleName = request.moduleParams.moduleName
					const pageCompId = request.pageCompId
					onFailureDump({
						siteAssetsFailureMessage: e.message,
						moduleName,
						pageCompId,
						// add here as many data as you like
					})
					throw e
				})
				.then(({ result }) => result())
		},
		calcPublicModuleUrl(request: TBSiteAssetsRequest): string {
			return siteAssetsClient.getPublicUrl(
				toSiteAssetsRequest(
					request,
					manifests.node.modulesToHashes,
					sitePagesModel.pageJsonFileNames,
					siteScopeParams,
					beckyExperiments,
					staticHTMLComponentUrl,
					remoteWidgetStructureBuilderVersion,
					deviceInfo,
					qaMode
				)
			)
		},
		getInitConfig() {
			return config
		},
	}
}
